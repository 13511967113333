/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import * as React from 'react'
import { PageProps } from 'gatsby'
import * as web3 from '@solana/web3.js'
import { useEffect, useState } from 'react'
import { PublicKey } from '@solana/web3.js'
import * as s from '../styles/drop-list.module.scss'

type PhantomEvent = 'disconnect' | 'connect' | 'accountChanged'
interface ConnectOpts {
  onlyIfTrusted: boolean
}
interface PhantomProvider {
  connect: (opts?: Partial<ConnectOpts>) => Promise<{ publicKey: PublicKey }>
  disconnect: () => Promise<void>
  on: (event: PhantomEvent, callback: (args: any) => void) => void
  isPhantom: boolean
}

type WindowWithSolana = Window & {
  solana?: PhantomProvider
}

const IndexPage = ({ location }: PageProps) => {
  const [publicKey, setPublicKey] = useState<PublicKey | null>(null)
  const [balance, setBalance] = useState(0 as number)
  const [error, setError] = useState(false)
  const [provider, setProvider] = useState<PhantomProvider | null>(null)
  const [walletAvail, setWalletAvail] = useState(false)
  const [connected, setConnected] = useState(false)

  useEffect(() => {
    if ('solana' in window) {
      const solWindow = window as WindowWithSolana
      if (solWindow?.solana?.isPhantom) {
        setProvider(solWindow.solana)
        setWalletAvail(true)
      }
    }
  }, [])

  useEffect(() => {
    provider?.on('connect', (publicKey: PublicKey) => {
      setConnected(true)
      setPublicKey(publicKey)
    })
    provider?.on('disconnect', () => {
      setConnected(false)
      setPublicKey(null)
    })
  }, [provider])

  const getBalance = async () => {
    const connection = new web3.Connection(
      web3.clusterApiUrl('mainnet-beta'),
      'confirmed'
    )
    if (publicKey) {
      setBalance(await connection.getBalance(publicKey))
    }
  }

  const connect = async () => {
    try {
      await provider?.connect()
    } catch (err: any) {
      setError(err.message)
    }
  }

  const disconnect = async () => {
    try {
      await provider?.disconnect()
    } catch (err: any) {
      setError(err.message)
    }
  }

  return (
    <main className={s.container}>
      Home Page {location.pathname}
      {walletAvail && (
        <div>
          {!connected ? (
            <div onClick={connect} className='button'>
              Connect
            </div>
          ) : (
            <div onClick={disconnect} className='button'>
              Disconnect
            </div>
          )}
        </div>
      )}
      {connected ? <p>Your public key is : {publicKey?.toBase58()}</p> : null}
      <div onClick={getBalance} className='button'>
        Balance: {balance}
      </div>
      {error && <div>{error}</div>}
    </main>
  )
}

export default IndexPage
